* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.wrapper {
  font-weight: 100;
  letter-spacing: -1px;
}

.card {
  perspective: 150rem;
  width: 350px;
  height: 490px;
  position: relative;
}

.card-side {
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 490px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  -moz-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  backface-visibility: hidden;
  top: 0px;
  transition: all 0.8s ease;
}
.card-side.front:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -0px;
  opacity: 1;
  -moz-transform: rotate(65deg) translate(175px, 20px);
  -ms-transform: rotate(65deg) translate(175px, 20px);
  -webkit-transform: rotate(65deg) translate(175px, 20px);
  transform: rotate(65deg) translate(175px, 20px);
}
.card-side.front.purple:before {
  background: #5d3cc2;
}
.card-side.front.blue:before {
  background: #0066cc;
}
.card-side.front .content {
  z-index: 10;
  position: absolute;
  left: 50%;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  transform: translate(-50%, -50%);
  top: 20%;
  opacity: 1;
}
.card-side.back .content {
  z-index: 10;
  position: absolute;
  opacity: 1;
  padding-top: 40px;
}
.card-side .content h1 {
  font-size: 60px;
  line-height: 40px;
  margin: 0;
}
.card-side .content h2 {
  margin: 0;
  font-size: 36px;
  line-height: 36px;
  color: #000000;
}
.card-side .content h2 sup {
  font-size: 14px;
}

.card-side.back .content h2 {
  text-align: center;
}

.card-side.blue h2 {
  color: #0066cc;
}

.card-side.purple h2 {
  color: #5d3cc2;
}

.timer h3 {
  color: #0066cc;
  padding-top: 10px;
}

.card-side.back .content h3 {
  font-size: 22px;
  line-height: 32px;
  margin: 25px 20px 0 20px;
  text-align: center;
}

span.dark,
div.content h1 {
  text-transform: uppercase;
}

.dark {
  color: #0c0b13;
}

.card-side .front-image {
  position: absolute;
  top: auto;
  left: auto;
  bottom: 16px;
  right: 16px;
  color: #e1623f;
  max-width: 50%;
}

.card-side .back-image {
  position: absolute;
  top: auto;
  left: auto;
  bottom: 16px;
  right: 16px;
  color: #e1623f;
  max-width: 25%;
}

.front {
  transform: rotateY(0deg);
  background: #ffffff;
}

.front.flip {
  transform: rotateY(-180deg);
}

.back {
  transform: rotateY(180deg);
  background: #ffffff;
}

.back.flip {
  transform: rotateY(0deg);
}
